import React, { useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import Layout from '../components/Layout';
import useDashboardData from '../hooks/useDashboardData';
import Map from '../components/Map';
import '../styles/Dashboard.css';
import { MapHandleZoom } from '../components/Map';

type PointOfInterest = {
  id: string;
  Latitude: number;
  Longitude: number;
  createdAt: string | number;
  rank: number;
  stdError: number;
  dateFirstAppeared: Date;
  company: string;
  notes: string;
};

const Dashboard: React.FC = () => {
  // Retrieve session data and POI data using useDashboardData()
  const { session, pointsOfInterest } = useDashboardData();
  const email = session?.user?.email;
  const mapRef = useRef<MapHandleZoom>(null); // for zooming to location
  const mapContainerRef = useRef<HTMLDivElement>(null); // for scrolling up to map

  // Format points of interest data, excluding Supabase's internal fields `createdAt` and `ID`
  const formattedPoints = pointsOfInterest
    .map((poi) => ({
      id: poi.id.toString(),
      Latitude: Number(poi.Latitude),
      Longitude: Number(poi.Longitude),
      createdAt: poi.created_at,
      rank: Number(poi.Rank),
      stdError: Number(poi['Standard Deviation of Error (m)']),
      dateFirstAppeared: new Date(poi['Date POI First appeared']),
      company: String(poi['Company']),
      notes: String(poi.Notes),
    }))
    .sort((a, b) => a.rank - b.rank);

  // Calculate min and max ranks to pass to Map for color gradient scaling
  const minRank = Math.min(...formattedPoints.map((poi) => poi.rank));
  const maxRank = Math.max(...formattedPoints.map((poi) => poi.rank));
  const isAnimatingRef = useRef(false); // Track animation state
  const handlePinClick = (poi: PointOfInterest) => {
    if (isAnimatingRef.current) return;
    else {
      mapContainerRef.current?.scrollTo({ behavior: 'smooth', top: 100 });
      mapRef.current?.zoomToLocation(poi.Latitude, poi.Longitude, poi);
    }
  };

  function Points({ currentPoints }: { currentPoints: PointOfInterest[] }) {
    return (
      <>
        {currentPoints &&
          currentPoints.map((point) => (
            <tr key={point.id} className="flex-row">
              <td>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={'../assets/location_pin.png'}
                    alt={point.rank.toString()}
                    className="location-pin"
                    style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                    onClick={() => handlePinClick(point)}
                  />
                  <span>{point.rank}</span>
                </div>
              </td>
              <td>{point.Latitude}</td>
              <td>{point.Longitude}</td>
              <td>{point.stdError}</td>
              <td>{point.dateFirstAppeared.toLocaleDateString()}</td>
              <td>{point.notes}</td>
            </tr>
          ))}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }: { itemsPerPage: number }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = formattedPoints.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(formattedPoints.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event: { selected: number }) => {
      const newOffset =
        (event.selected * itemsPerPage) % formattedPoints.length;
      setItemOffset(newOffset);
    };
    return (
      <>
        <Points currentPoints={currentItems} />
        <tr>
          <td colSpan={6} style={{ textAlign: 'center' }}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="< previous"
              renderOnZeroPageCount={null}
              containerClassName="react-paginate" // Add a class for styling
              activeClassName="selected" // Highlight the active page
            />
          </td>
        </tr>
      </>
    );
  }

  return (
    <Layout>
      <h1 className="Dashboard">Dashboard</h1>
      {email && <h3>Hello, {email}</h3>}

      {session && (
        <>
          {/* Show the dashboard only if the user is logged in*/}
          <h1 className="MapViewText">Map View</h1>
        </>
      )}

      {session ? (
        <>
          {/* Map View: display POIs on a map */}
          <div className="map" ref={mapContainerRef}>
            <Map
              ref={mapRef}
              pointsOfInterest={formattedPoints}
              minRank={minRank}
              maxRank={maxRank}
              email={session?.user?.email || ''}
            />
          </div>
          {/* Data View: display POI data in table format */}
          <h1 className="DataViewText">Data View</h1>
          <div className="container">
            <div className="dataView">
              <table>
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Latitude</th>
                    <th>Longitude</th>
                    <th>Standard Deviation of Error (m)</th>
                    <th>Date First Appeared</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody>
                  <PaginatedItems itemsPerPage={10} />
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        // If the user is logged out, prompt a login
        <h2>
          Please{' '}
          <a href="/login" style={{ textDecoration: 'underline' }}>
            log in
          </a>{' '}
          to view the dashboard...
        </h2>
      )}
    </Layout>
  );
};

export default Dashboard;
