// node modules initialisation
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import useDashboardData from '../hooks/useDashboardData';

// pages initialisation
import { HeaderAuth } from './HeaderAuth';

// stylesheet initialisation
import '../styles/App.css'; // default stylesheet
import '../styles/Header.css'; // header stylesheet
import { useAuth } from '../context/AuthContext';

// constant initialisation
const logoPath = '/assets/icon.png';

// private functions
function Logo() {
  return <img src={logoPath} className="App-logo" alt="logo" />;
}

function Navigation() {
  const [expanded, setExpanded] = useState(false);
  const { state, isInitialized } = useAuth();
  const { session } = useDashboardData();

  if (!isInitialized) {
    return null;
    //return null; Do not render anything until the auth state is initialized
  }

  return (
    <Navbar
      expand="lg"
      bg="default"
      variant="dark"
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          aria-label="Toggle navigation"
          aria-expanded={expanded}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto default-font align-items-center">
            <Nav.Link
              as={Link}
              to="/home"
              className={`py-3 ${!expanded ? 'me-3' : ''}`}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={session ? '/Dashboard' : '/Login'}
              className={`py-3 ${!expanded ? 'me-3' : ''}`}
            >
              Dashboard
            </Nav.Link>
            <Nav.Link
              href="https://polychord.io/about.php"
              target="_blank"
              rel="noopener noreferrer"
              className={`py-3 ${!expanded ? 'me-3' : ''}`}
            >
              About
            </Nav.Link>
            <Nav.Link
              href="https://polychord.io/contact.php"
              target="_blank"
              rel="noopener noreferrer"
              className={`py-3 ${!expanded ? 'me-4' : ''}`}
            >
              Contact Us
            </Nav.Link>
            <HeaderAuth state={state} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

// public functions
function Header() {
  return (
    <header>
      <Navigation />
    </header>
  );
}

export default Header;
