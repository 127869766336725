import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/Map.css';

// Check for .env variable to render the map
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
if (!mapboxgl.accessToken) {
  throw new Error(
    'Please set a valid REACT_APP_MAPBOX_TOKEN in your .env file',
  );
}

// POI interface solely for rendering markers on the map
interface PointOfInterest {
  Latitude: number;
  Longitude: number;
  rank: number;
  stdError: number;
  dateFirstAppeared: Date;
  company: string;
  notes: string;
}

interface MapProps {
  pointsOfInterest: PointOfInterest[];
  minRank: number;
  maxRank: number;
  email: string;
}
export interface MapHandleZoom {
  zoomToLocation: (
    latitude: number,
    longitude: number,
    poi: PointOfInterest,
  ) => void;
} // Handles zooming to a location on the map

const INITIAL_ZOOM = 9;

// RGB color stops for a full spectrum gradient from red to blue
const colorStops = [
  [255, 0, 0],
  [255, 165, 0],
  [255, 255, 0],
  [0, 128, 0],
  [0, 0, 255],
];

// Function to get the color for a rank based on the color stops
const getColorForRank = (rank: number, minRank: number, maxRank: number) => {
  const ratio = (rank - minRank) / (maxRank - minRank);
  const segment = Math.floor(ratio * (colorStops.length - 1));
  const segmentRatio = (ratio * (colorStops.length - 1)) % 1;

  const startColor = colorStops[segment];
  const endColor = colorStops[Math.min(segment + 1, colorStops.length - 1)];

  const r = Math.round(
    startColor[0] * (1 - segmentRatio) + endColor[0] * segmentRatio,
  );
  const g = Math.round(
    startColor[1] * (1 - segmentRatio) + endColor[1] * segmentRatio,
  );
  const b = Math.round(
    startColor[2] * (1 - segmentRatio) + endColor[2] * segmentRatio,
  );

  return `rgb(${r},${g},${b})`;
};

const Map = forwardRef<MapHandleZoom, MapProps>(
  ({ pointsOfInterest, minRank, maxRank, email }, ref) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<mapboxgl.Map | null>(null);
    const [zoom, setZoom] = useState(INITIAL_ZOOM);
    const [selectedPoint, setSelectedPoint] = useState<PointOfInterest | null>(
      null,
    );

    const getInitialCenter = (email: string): [number, number] => {
      const domain = email.split('@')[1];
      switch (domain) {
        case 'westmidlandsmetro.com':
          return [-1.9219, 52.5079];
        case 'edinburghtrams.com':
          return [-3.1734, 55.936];
        default:
          return [-1.9219, 52.5079];
      }
    };

    const [INITIAL_CENTER] = useState<[number, number]>(
      getInitialCenter(email),
    );

    const popupRef = useRef<mapboxgl.Popup | null>(null);

    useImperativeHandle(ref, () => ({
      zoomToLocation: (
        latitude: number,
        longitude: number,
        poi: PointOfInterest,
      ) => {
        setTimeout(() => {
          mapRef.current?.stop();
          mapRef.current?.flyTo({
            center: [longitude, latitude],
            zoom: 18,
            essential: true,
          });
        }, 1000);
        setSelectedPoint(poi); //zoom to location and see the information about the point
        if (popupRef.current) {
          popupRef.current.remove();
        }
        const newPopup = new mapboxgl.Popup({
          closeOnClick: true,
          offset: [0, -25],
        })
          .setLngLat([longitude, latitude])
          .setHTML(
            `
          <div id="map-popup">
          <p>Rank: ${poi.rank}<p>
          <p>Latitude: ${latitude}</p>
          <p>Longitude: ${longitude}</p>
          <p>Notes: ${poi.notes}</p>
          </div>
          `,
          )
          .addTo(mapRef.current!);
        popupRef.current = newPopup;
      },
    }));
    const [currentCenter, setCurrentCenter] =
      useState<[number, number]>(INITIAL_CENTER);
    useEffect(() => {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current!,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: INITIAL_CENTER,
        zoom: INITIAL_ZOOM,
      });
      const geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showAccuracyCircle: true,
      });
      mapRef.current.addControl(geolocateControl, 'top-right');
      mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
      mapRef.current.addControl(new mapboxgl.FullscreenControl(), 'top-right');

      mapRef.current.on('load', () => {
        mapRef.current!.addSource('mapbox-streets', {
          type: 'vector',
          url: 'mapbox://mapbox.mapbox-streets-v8',
        });

        // Rail colour
        mapRef.current!.addLayer(
          {
            id: 'roads-layer',
            type: 'line',
            source: 'mapbox-streets',
            'source-layer': 'road',
            filter: [
              'in',
              'class',
              'major_rail',
              'minor_rail',
              'rail',
              'light_rail',
              'subway',
              'tram',
              'funicular',
              'monorail',
              'narrow_gauge',
              'preserved',
              'construction',
            ],
            // Add colors for each type of rail, with firebrick (earth brown) as fallback

            paint: {
              'line-color': [
                'match',
                ['get', 'class'],
                'major_rail',
                '#FF4500', // vivid orange-red
                'minor_rail',
                '#9400D3', // dark violet
                'rail',
                '#1E90FF', // dodger blue
                'light_rail',
                '#FF1493', // deep pink
                'subway',
                '#FF8C00', // dark orange
                'tram',
                '#00CED1', // dark turquoise
                'funicular',
                '#FFD700', // gold
                'monorail',
                '#8A2BE2', // blue violet
                'narrow_gauge',
                '#C71585', // medium violet red
                'preserved',
                '#32CD32', // lime green
                'construction',
                '#FF0000', // bright red
                '#B22222', // Fallback: firebrick
              ],
              'line-width': [
                'interpolate',
                ['linear'],
                ['zoom'],
                0,
                1,
                12,
                3,
                22,
                8,
              ],
            },
            layout: {
              'line-cap': 'round',
              'line-join': 'round',
            },
          },
          'waterway',
        );

        // Add markers for points of interest
        pointsOfInterest.forEach((poi) => {
          if (poi.Latitude && poi.Longitude) {
            // Calculate color based on rank using gradient
            const color = getColorForRank(poi.rank, minRank, maxRank);

            const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
              `<div>
                <p><b>Rank:</b> ${poi.rank}</p>
                <p><b>Latitude:</b> ${poi.Latitude.toFixed(4)}</p>
                <p><b>Longitude:</b> ${poi.Longitude.toFixed(4)}</p>
                <p><b>Date First Appeared:</b> ${new Date(
                  poi.dateFirstAppeared,
                ).toLocaleDateString()}</p>
                <p><b>Company:</b> ${poi.company}</p>
                <p><b>Notes:</b> ${poi.notes}</p>
              </div>`,
            );
            const marker = new mapboxgl.Marker({ color })
              .setLngLat([poi.Longitude, poi.Latitude])
              .setPopup(popup)
              .addTo(mapRef.current!);

            // Hidden by default
            marker.getElement().style.display = 'none';

            // Show/hide markers based on zoom level
            mapRef.current!.on('zoom', () => {
              const currentZoom = mapRef.current!.getZoom();
              // Show markers only when zoomed in
              marker.getElement().style.display =
                currentZoom > 11 ? 'block' : 'none';
            });

            marker.getElement().addEventListener('mouseenter', () => {
              mapRef.current!.getCanvas().style.cursor = 'pointer';
            });

            marker.getElement().addEventListener('mouseleave', () => {
              mapRef.current!.getCanvas().style.cursor = '';
            });
            marker.getElement().addEventListener('click', () => {
              if (popupRef.current) {
                popupRef.current.remove();
              }
              setSelectedPoint(poi);
              popupRef.current = popup;
            });
          }
        });

        // Add click event listener for displaying popup with rail type
        mapRef.current!.on('click', 'roads-layer', (e) => {
          const features = e.features;

          if (features && features.length > 0) {
            const railType = features[0].properties?.class || 'N/A';

            new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(`<p><b>Rail type: ${railType}<b></p>`)
              .addTo(mapRef.current!);
          }
        });

        // Change the cursor to pointer when hovering over the roads layer
        mapRef.current!.on('mouseenter', 'roads-layer', () => {
          mapRef.current!.getCanvas().style.cursor = 'pointer';
        });

        mapRef.current!.on('mouseleave', 'roads-layer', () => {
          mapRef.current!.getCanvas().style.cursor = '';
        });

        // Add heatmap source
        mapRef.current!.addSource('poi-heat', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: pointsOfInterest.map((poi) => ({
              type: 'Feature',
              properties: {
                intensity: poi.stdError,
              },
              geometry: {
                type: 'Point',
                coordinates: [poi.Longitude, poi.Latitude],
              },
            })),
          },
        });

        // Add heatmap layer
        mapRef.current!.addLayer({
          id: 'poi-heat',
          type: 'heatmap',
          source: 'poi-heat',
          paint: {
            // Increase weight based on standard deviation
            'heatmap-weight': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.1, // Equal weight at low zoom for density
              10,
              ['/', ['get', 'intensity'], 5], // Start increasing at city level
              15,
              ['/', ['get', 'intensity'], 5], // Scale by stdError at high zoom
            ],
            // Increase intensity as zoom level increases
            'heatmap-intensity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              0.3, // Lower intensity for density view
              15,
              2, // Higher intensity for detailed view
            ],
            // Color gradient
            'heatmap-color': [
              'interpolate',
              ['linear'],
              ['heatmap-density'],
              0,
              'rgba(33,102,172,0)',
              0.2,
              'rgb(103,169,207)',
              0.4,
              'rgb(209,229,240)',
              0.6,
              'rgb(253,219,199)',
              0.8,
              'rgb(239,138,98)',
              1,
              'rgb(178,24,43)',
            ],
            // Adjust radius with zoom
            'heatmap-radius': [
              'interpolate',
              ['exponential', 1.5],
              ['zoom'],
              0,
              20, // Large enough to show density
              10,
              30, // Medium zoom
              15,
              ['*', ['get', 'intensity'], 8], // Start using stdError
              20,
              ['*', ['get', 'intensity'], 13], // Max zoom
            ],
            'heatmap-opacity': 0.8,
          },
        });
      });
      // Update map center and zoom state on map move
      mapRef.current.on('move', () => {
        const mapCenter = mapRef.current!.getCenter();
        const mapZoom = mapRef.current!.getZoom();
        setCurrentCenter([mapCenter.lng, mapCenter.lat]);
        setZoom(mapZoom);
      });

      // Cleanup on component unmount
      return () => {
        if (popupRef.current) {
          popupRef.current.remove();
        }
        mapRef.current!.remove();
      };
    }, [pointsOfInterest, minRank, maxRank, INITIAL_CENTER]);

    // Reset map to initial center and zoom

    const handleReset = () => {
      mapRef.current!.flyTo({ center: INITIAL_CENTER, zoom: INITIAL_ZOOM });
    };

    return (
      <div className="dashboard">
        <div className="info-box">
          {selectedPoint ? (
            <div>
              <h2>
                <b>POI DETAILS</b>
              </h2>
              <p>
                <b>Rank:</b> {selectedPoint.rank}
              </p>
              <p>
                <b>Latitude:</b> {selectedPoint.Latitude}
              </p>
              <p>
                <b>Longitude:</b> {selectedPoint.Longitude}
              </p>
              <p>
                <b>Standard deviation of error:</b> {selectedPoint.stdError}
              </p>
              <p>
                <b>Date first appeared:</b>{' '}
                {selectedPoint.dateFirstAppeared.toLocaleDateString()}
              </p>
              <p>
                <b>Company:</b> {selectedPoint.company}
              </p>
              <p>
                <b>Notes:</b> {selectedPoint.notes}
              </p>
            </div>
          ) : (
            <div>
              <h2>Click on a point of interest to view its information.</h2>
            </div>
          )}
        </div>
        <div className="map-wrapper">
          <div ref={mapContainerRef} className="map-container" />
          <div className="map-overlay">
            <div>
              Long: {currentCenter[0].toFixed(4)} | Lat:{' '}
              {currentCenter[1].toFixed(4)} | Zoom: {zoom.toFixed(2)}
            </div>
            <button onClick={handleReset} className="reset-button">
              Reset
            </button>
          </div>
        </div>
      </div>
    );
  },
);

Map.displayName =
  'Map'; /* To pass the lint tests, React.FC automatically infers the display name but we are using a function here
so we need to explicitly set the display name */

export default Map;
